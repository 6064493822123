import React from 'react';
import { useDrag } from 'react-dnd';

const LetterTile = ({ index, letter, blockLetterTile }) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'LETTER',
    item: { letter },
    //end: (item) => blockLetterTile(letter, index),
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  

  return (
    <div
      ref={drag}
      style={{
        padding: '5px',
        margin: '5px',
        width: '55px',
        height: '30px',
        backgroundColor: 'lightblue',
        border: '1px solid gray',
        borderRadius: '5px',
        cursor: 'move',
        opacity: isDragging ? 0.5 : 1,
        fontSize: '24px',
        fontWeight: 'bold',
      }}
    >
      {letter}
    </div>
  );
};

export default LetterTile;
