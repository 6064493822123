import React from 'react';
import { useDrop } from 'react-dnd';

const WordSlot = ({ children, index, onDrop, forRemove }) => {
  const [{ isOver }, drop] = useDrop(() => ({
    accept: 'LETTER',
    drop: (item) => onDrop(item.letter, index),
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  }));

  return (
    <div onClick={ (e) => forRemove (index, children) }
      ref={drop}
      style={{
        width: '60px',
        height: '60px',
        margin: '5px',
        border: '2px solid black',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: isOver ? 'lightgreen' : 'white',
        fontSize: '24px',
        fontWeight: 'bold',
      }}
    >
      {children}
    </div>
  );
};

export default WordSlot;
