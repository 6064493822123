import React, { useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import LetterTile from './LetterTile';
import WordSlot from './WordSlot';
import './App.css';
import GraphemeSplitter from 'grapheme-splitter'; //for tamil font
import TopMenu from './TopMenu';

const word = "வாழை_பழம்";
const answer = "சிங்கம்";
const splitter = new GraphemeSplitter(); //for tamil font

const shuffleWord = (word) => {
  //return word.split('').sort(() => Math.random() - 0.5).join(''); //for english
  return splitter.splitGraphemes(word).sort(() => Math.random() - 0.5).join(''); //for tamil
};

function App() {
  const [jumbledWord, setJumbledWord] = useState(shuffleWord(word));
  const [placedLetters, setPlacedLetters] = useState(Array(word.length).fill(null));
  //const [availableLetters, setAvailableLetters] = useState(jumbledWord.split('')); //English
  const [availableLetters, setAvailableLetters] = useState(splitter.splitGraphemes(jumbledWord)); //Tamil

  const handleDrop = (letter, index) => {
    console.log("droping " + letter + " from " + index);
    setPlacedLetters(prevItems =>
      prevItems.map((item, i) => (i === index ? letter : item))
    );
  };

  const handleWordRemove = (index, letter) => {
    //alert("index to clear : " + index + " and letter : " + letter);
    setPlacedLetters(prevItems =>
      prevItems.map((item, i) => (i === index ? null : item))
    );

    /*
    //adding back the letter to the availableLetters useState at first null space
    setAvailableLetters(prevItems => 
      prevItems.map((item, i) =>(item === null ? letter : item))
    );
    */
  }

  const blockLetterTile = (letter, index) => {
    alert(letter + " at " + index);

    setAvailableLetters(prevItems =>
      prevItems.map((item, i) => (i === index ? null : item))
    );

    /*
    // Use the filter method to create a new array without the item at the given index
    console.log("availableLetters : " + availableLetters);
    const updatedItems = availableLetters.filter((_, i) => i !== index);
    
    setAvailableLetters(updatedItems); // Update the state with the new array
    console.log("new availableLetters : " + availableLetters);
    */
  }

  const isCorrect = placedLetters.join('') === word;

  // Determine whether to use HTML5 or Touch backend based on the device
  const isTouchDevice = 'ontouchstart' in window;
  const backend = isTouchDevice ? TouchBackend : HTML5Backend;

  return (
    <DndProvider backend={backend}>

      <TopMenu></TopMenu>

      <div style={{ textAlign: 'center', marginTop: '50px' }}>
        <h1>Jumble Word Game</h1>
        <p>Drag and drop the letters animal name</p>

        <img src='https://upload.wikimedia.org/wikipedia/commons/thumb/6/69/Banana.png/1200px-Banana.png' className="hint-image" />

        <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
          {availableLetters.map((letter, index) => (
            <LetterTile key={index} index={index} letter={letter} blockLetterTile={blockLetterTile} />
          ))}
        </div>

        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {splitter.splitGraphemes(word).map((_, index) => (
            <WordSlot key={index} index={index} onDrop={handleDrop} forRemove={handleWordRemove}>
              {placedLetters[index]}
            </WordSlot>
          ))}
        </div>

        {/*
        {isCorrect && <h2>Congratulations! You guessed the word!</h2>}
        */}
        {isCorrect ? <h2>Congratulations! You guessed the word!</h2> : <h2>!!....!!</h2>}

        <div className='paging-game-page'>
          <div className='backward'>
            <a href='#'>
              <img src='https://cdn-icons-png.flaticon.com/512/2099/2099190.png' />
            </a>
          </div>
          <div className='forward'>
            <a href='#'>
              <img src='https://cdn-icons-png.flaticon.com/512/137/137530.png' />
            </a>
          </div>
        </div>
      </div>
    </DndProvider>
  );
}

export default App;
