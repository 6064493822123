export default function TopMenu(){

    return(
        <>
            <div className="top-menu">
                <a href="#" className="logo-menu">
                    <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTk1Q2lh7aweiNuE3LObL2pp_kWhtffSJckKw&s" />
                </a>
                <a href="#" className="category-menu">
                    <img src="https://static-00.iconduck.com/assets.00/cog-icon-2048x2045-7xru0z26.png" />
                    Categories
                </a>
            </div>
        </>
    )

}